import React from 'react';
import AItoolsPage from './components/AItoolsPage';
import 'tailwindcss/tailwind.css';

function App() {
  return (
    <div className="App">
      <AItoolsPage />
    </div>
  );
}

export default App;

